import { ApiActionType, ApiResource } from './types'

export class ApiActionEvent {
  id = 'api.action'
  constructor(readonly data: ApiActionEventData) {}
}

export interface ApiActionEventData {
  type: ApiActionType
  resource: ApiResource
  resourceId?: string
  data?: any
  error?: any
}

export class CreateChallengeSuccessEvent extends ApiActionEvent {
  readonly id = 'api.action.create-challenge.success'
}

export class CreateChallengeFailureEvent extends ApiActionEvent {
  readonly id = 'api.action.create-challenge.error'
}

export class VerifySignatureSuccessEvent extends ApiActionEvent {
  readonly id = 'api.action.verify-signature.success'
}

export class VerifySignatureFailureEvent extends ApiActionEvent {
  readonly id = 'api.action.verify-signature.error'
}

export class EditUserErrorEvent extends ApiActionEvent {
  readonly id = 'api.action.edit-user.error'
}
