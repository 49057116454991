var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/hooks/useApi.ts
import { usePluginState } from "plugin-system";
var useApi = () => {
  return usePluginState("api", {});
};

// src/hooks/useAllPages.ts
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
function useAllPages(queryKey, cursorQueryFn, queryOptions2) {
  const [allData, setAllData] = useState([]);
  const [hasSetData, setHasSetData] = useState(false);
  const { data, fetchNextPage, hasNextPage, isError, isFetching, isPending, isFetched, isLoading } = useInfiniteQuery(__spreadValues({
    queryKey: typeof queryKey === "string" ? [queryKey] : queryKey,
    initialPageParam: void 0,
    queryFn: ({ pageParam }) => cursorQueryFn(pageParam).then((res) => res.data),
    getNextPageParam: (lastPage) => {
      const castedLastPage = lastPage;
      return castedLastPage.has_more ? castedLastPage.cursor : void 0;
    }
  }, queryOptions2 != null ? queryOptions2 : {}));
  useEffect(() => {
    if (hasNextPage && data && (queryOptions2 == null ? void 0 : queryOptions2.enabled) !== false) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, data, queryOptions2 == null ? void 0 : queryOptions2.enabled]);
  useEffect(() => {
    var _a;
    if (isPending || hasNextPage || !(data == null ? void 0 : data.pages.length) || (queryOptions2 == null ? void 0 : queryOptions2.enabled) === false) {
      setAllData((old) => !old.length ? old : []);
      setHasSetData(false);
    } else if (((_a = data == null ? void 0 : data.pages) == null ? void 0 : _a.length) && !isPending && !hasNextPage) {
      setAllData(data == null ? void 0 : data.pages.flatMap((page) => {
        var _a2;
        return (_a2 = page.results) != null ? _a2 : [];
      }));
      setHasSetData(true);
    }
  }, [isPending, hasNextPage, data, queryOptions2 == null ? void 0 : queryOptions2.enabled]);
  return {
    isPending: isPending || hasNextPage || !hasSetData,
    isFetched,
    isFetching,
    isLoading,
    isError,
    data: allData
  };
}
var useAllPages_default = useAllPages;

// src/constants.ts
var CORE_DEFAULT_API_PAGINATION_LIMIT = 100;
var CORE_MEDIUM_API_PAGINATION_LIMIT = 1e3;
var CORE_HIGH_API_PAGINATION_LIMIT = 1e4;
var CORE_API_SORT_DIRECTION_ASC = "ASC";
var CORE_API_SORT_DIRECTION_DESC = "DESC";
var SHORT_LIVED_QRY_STALE = 1e3 * 30;
var MEDIUM_LIVED_STALE_QUERY = 1e3 * 60 * 5;
var LONG_LIVED_QRY_STALE = 1e3 * 60 * 30;
var SHORT_LIVED_QRY_REFETCH_INTERVAL = 5e3;
var MEDIUM_LIVED_QRY_REFETCH_INTERVAL = 3e4;
var LONG_LIVED_QRY_REFETCH_INTERVAL = 6e4;
var SHORT_LIVED_QRY_CONFIG = {
  staleTime: SHORT_LIVED_QRY_STALE
};
var MEDIUM_LIVED_QRY_CONFIG = {
  staleTime: MEDIUM_LIVED_STALE_QUERY
};
var LONG_LIVED_QRY_CONFIG = {
  staleTime: LONG_LIVED_QRY_STALE
};

// src/hooks/role.ts
var useAllRoles = (options) => {
  const { coreApi } = useApi();
  const results = useAllPages_default(
    ["ROLE", "ALL"],
    (cursor) => coreApi.rolesGet(void 0, cursor, CORE_DEFAULT_API_PAGINATION_LIMIT, void 0),
    __spreadValues(__spreadValues({}, LONG_LIVED_QRY_CONFIG), options != null ? options : {})
  );
  return results;
};

// src/hooks/publicNetwork.ts
import { useMemo } from "react";
import { orderBy } from "lodash";
var useAllPublicNetworks = () => {
  const { coreApiPublic } = useApi();
  const result = useAllPages_default(
    ["NETWORK", "PUBLIC", "ALL"],
    (cursor) => coreApiPublic.v1NetworksGet(
      CORE_API_SORT_DIRECTION_ASC,
      cursor,
      CORE_DEFAULT_API_PAGINATION_LIMIT
    ),
    {
      // this should rarely change
      staleTime: Infinity,
      gcTime: Infinity
    }
  );
  const results = useMemo(() => {
    if (!(result == null ? void 0 : result.data))
      return result;
    const networks = result.data;
    for (let index = 0; index < networks.length; index++) {
      const network = networks[index];
      if (network.id === 1)
        networks[index].name = "Ethereum";
    }
    return __spreadProps(__spreadValues({}, result), {
      data: orderBy(networks, ["testnet", "name"], ["asc", "asc"])
    });
  }, [result]);
  return results;
};

// src/hooks/useResourceQuery.ts
import { useQuery, useQueryClient } from "@tanstack/react-query";
function useResourceQuery(queryKey, queryFn, queryOptions2) {
  const result = useQuery(__spreadValues({
    queryKey,
    queryFn
  }, queryOptions2 != null ? queryOptions2 : {}));
  return __spreadProps(__spreadValues({}, result), {
    isPending: result.isPending && (queryOptions2 == null ? void 0 : queryOptions2.enabled) !== false && !result.isError
  });
}
function usePaginatedResourceQuery(queryKey, queryFn, queryOptions2) {
  return useQuery(__spreadValues({
    queryKey,
    queryFn
  }, queryOptions2 != null ? queryOptions2 : {}));
}
function useInvalidateResourceQuery() {
  const queryClient = useQueryClient();
  return (resource, ...otherQueryKeyElements) => __async(this, null, function* () {
    if (otherQueryKeyElements.length > 0) {
      return queryClient.invalidateQueries({ queryKey: [resource, ...otherQueryKeyElements] });
    } else {
      return queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(resource)
      });
    }
  });
}

// src/hooks/drop.ts
import {
  useInfiniteQuery as useInfiniteQuery2,
  useMutation
} from "@tanstack/react-query";
import debounce from "debounce-async";
import { usePluginRegistry } from "plugin-system";
import { useEffect as useEffect2, useMemo as useMemo2, useState as useState2 } from "react";

// src/events.ts
var ApiActionEvent = class {
  constructor(data) {
    this.data = data;
    this.id = "api.action";
  }
};
var CreateChallengeSuccessEvent = class extends ApiActionEvent {
  constructor() {
    super(...arguments);
    this.id = "api.action.create-challenge.success";
  }
};
var CreateChallengeFailureEvent = class extends ApiActionEvent {
  constructor() {
    super(...arguments);
    this.id = "api.action.create-challenge.error";
  }
};
var VerifySignatureSuccessEvent = class extends ApiActionEvent {
  constructor() {
    super(...arguments);
    this.id = "api.action.verify-signature.success";
  }
};
var VerifySignatureFailureEvent = class extends ApiActionEvent {
  constructor() {
    super(...arguments);
    this.id = "api.action.verify-signature.error";
  }
};
var EditUserErrorEvent = class extends ApiActionEvent {
  constructor() {
    super(...arguments);
    this.id = "api.action.edit-user.error";
  }
};

// src/hooks/project.ts
var useAllProjects = (options) => {
  const { dropApi } = useApi();
  const results = useAllPages_default(
    ["PROJECT", "ALL"],
    (cursor) => dropApi.betaReadProjectControllerGetAllProjects(0, 100, true, cursor || "", 100),
    __spreadValues(__spreadValues({}, MEDIUM_LIVED_QRY_CONFIG), options || {})
  );
  return results;
};
var useProject = (projectId, options) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["PROJECT", projectId],
    () => dropApi.betaReadProjectControllerGetProjectById(projectId).then((res) => res == null ? void 0 : res.data),
    __spreadValues({
      enabled: Boolean(projectId)
    }, options || {})
  );
};

// src/hooks/drop.ts
var useAllDropItems = (dropId) => {
  const { dropApi } = useApi();
  const apiResult = useAllPages_default(
    ["DROP", dropId, "DROP_ITEM"],
    (cursor) => dropApi.betaReadDropItemControllerGetDropItemsForDrop(
      dropId,
      0,
      1e4,
      true,
      cursor || "",
      1e4
    ),
    __spreadProps(__spreadValues({}, MEDIUM_LIVED_QRY_CONFIG), {
      enabled: Boolean(dropId)
    })
  );
  return {
    isPending: apiResult.isPending && Boolean(dropId),
    isError: apiResult.isError,
    data: apiResult == null ? void 0 : apiResult.data
  };
};
var usePaginatedDropFlowItems = (dropId, dropFlowId, {
  limit = 10,
  itemTitle
}, queryOptions2) => {
  const { dropApi } = useApi();
  return useInfiniteQuery2(__spreadValues(__spreadValues({
    queryKey: [
      "DROP",
      dropId,
      "DROP_FLOW",
      dropFlowId,
      "DROP_ITEM",
      "LIMIT",
      limit,
      "FILTER",
      itemTitle || ""
    ],
    queryFn: (_0) => __async(void 0, [_0], function* ({ pageParam: cursor }) {
      const res = yield dropApi.betaReadDropItemControllerGetPaginatedDropFlowItems(
        dropFlowId,
        itemTitle,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        true,
        cursor,
        limit
      );
      return __spreadValues({}, res.data);
    }),
    initialPageParam: void 0,
    getNextPageParam: (lastPage) => {
      const castedLastPage = lastPage;
      return castedLastPage.has_more ? castedLastPage.cursor : void 0;
    },
    enabled: Boolean(dropId) && Boolean(dropFlowId)
  }, MEDIUM_LIVED_QRY_CONFIG), queryOptions2 || {}));
};
var useDropFlowItemCount = (dropId, dropFlowId) => {
  var _a, _b, _c;
  const { data, isPending, isError } = usePaginatedDropFlowItems(dropId, dropFlowId, { limit: 1 });
  return { data: (_c = (_b = (_a = data == null ? void 0 : data.pages) == null ? void 0 : _a[0]) == null ? void 0 : _b.total_results) != null ? _c : 0, isPending, isError };
};
var usePaginatedDrops = ({
  limit = 10,
  nameContains,
  status,
  contractAddress,
  collectionId,
  has_project_id
}, queryOptions2, queryKey) => {
  const { dropApi } = useApi();
  return useInfiniteQuery2(__spreadValues(__spreadValues({
    queryKey: queryKey || [
      "DROP",
      "ALL",
      "LIMIT",
      limit,
      "NAME",
      nameContains || "",
      "STATUS",
      status || "",
      "COLLECTION",
      collectionId || "",
      "CONTRACT",
      contractAddress || ""
    ],
    queryFn: ({ pageParam: cursor }) => dropApi.betaReadDropControllerGetAllDrops(
      0,
      limit,
      true,
      cursor || "",
      limit,
      void 0,
      nameContains || void 0,
      status || void 0,
      collectionId || void 0,
      void 0,
      has_project_id || void 0,
      contractAddress || void 0
    ).then((res) => res == null ? void 0 : res.data),
    initialPageParam: void 0,
    getNextPageParam: (lastPage) => {
      const castedLastPage = lastPage;
      return castedLastPage.has_more ? castedLastPage.cursor : void 0;
    }
  }, MEDIUM_LIVED_QRY_CONFIG), queryOptions2 || {}));
};
var useDropByCollectionId = (collectionId, queryOptions2) => {
  const { data, isPending, isError, isLoading } = usePaginatedDrops(
    { limit: 1, collectionId },
    __spreadValues({ enabled: Boolean(collectionId) }, queryOptions2 != null ? queryOptions2 : {}),
    ["DROP", "ALL", "COLLECTION", collectionId || ""]
  );
  const drop = useMemo2(() => {
    var _a, _b, _c;
    return (_c = (_b = (_a = data == null ? void 0 : data.pages) == null ? void 0 : _a[0]) == null ? void 0 : _b.results) == null ? void 0 : _c[0];
  }, [data]);
  return { data: drop, isPending, isError, isLoading };
};
var useDropByContractAddress = (contractAddress) => {
  const { data, isPending, isError, isLoading } = usePaginatedDrops(
    { limit: 1, contractAddress },
    { enabled: Boolean(contractAddress) },
    ["DROP", "ALL", "CONTRACT", contractAddress || ""]
  );
  const drop = useMemo2(() => {
    var _a, _b, _c;
    return (_c = (_b = (_a = data == null ? void 0 : data.pages) == null ? void 0 : _a[0]) == null ? void 0 : _b.results) == null ? void 0 : _c[0];
  }, [data]);
  return { data: drop, isPending, isError, isLoading };
};
var useRecentDrops = (limit) => {
  const { data, isPending, isError } = usePaginatedDrops({ limit }, void 0, [
    "DROP",
    "ALL",
    "RECENT"
  ]);
  const drops = useMemo2(() => {
    var _a, _b, _c;
    return (_c = (_b = (_a = data == null ? void 0 : data.pages) == null ? void 0 : _a[0]) == null ? void 0 : _b.results) != null ? _c : [];
  }, [data]);
  return { data: drops, isPending, isError };
};
var useDraftDropPeek = () => {
  const { data, isPending, isError } = usePaginatedDrops({ limit: 1, status: "DRAFT" }, void 0, [
    "DROP",
    "ALL",
    "DRAFT"
  ]);
  const peek = useMemo2(() => {
    var _a, _b;
    return (_b = (_a = data == null ? void 0 : data.pages) == null ? void 0 : _a[0]) == null ? void 0 : _b.total_results;
  }, [data]);
  return { data: peek, isPending, isError };
};
var useDrop = (dropId, queryOptions2) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["DROP", dropId || ""],
    () => dropApi.betaReadDropControllerGetDropById(dropId).then(
      (res) => res == null ? void 0 : res.data
    ),
    __spreadValues(__spreadValues({
      enabled: Boolean(dropId)
    }, MEDIUM_LIVED_QRY_CONFIG), queryOptions2 != null ? queryOptions2 : {})
  );
};
var useDropFlow = (dropId, dropFlowId, queryOptions2) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["DROP", dropId || "", "DROP_FLOW", dropFlowId || ""],
    () => dropApi.betaReadDropFlowControllerGetDropFlow(dropFlowId).then(
      (res) => res == null ? void 0 : res.data
    ),
    __spreadValues(__spreadValues({
      enabled: Boolean(dropFlowId)
    }, MEDIUM_LIVED_QRY_CONFIG), queryOptions2 != null ? queryOptions2 : {})
  );
};
var useDropFlowWithItemSummary = (dropId, dropFlowId, queryOptions2) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["DROP", dropId || "", "DROP_FLOW", dropFlowId || ""],
    () => dropApi.betaReadDropFlowControllerGetDropFlowWithDropItemsSummary(
      dropFlowId
    ).then((res) => res == null ? void 0 : res.data),
    __spreadValues(__spreadValues({
      enabled: Boolean(dropFlowId)
    }, MEDIUM_LIVED_QRY_CONFIG), queryOptions2 != null ? queryOptions2 : {})
  );
};
var useDropItem = (dropId, dropFlowId, itemId, queryOptions2) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["DROP", dropId || "", "DROP_FLOW", dropFlowId || "", "DROP_ITEM", itemId || ""],
    () => dropApi.betaReadDropItemControllerGetDropItem(itemId).then(
      (res) => res == null ? void 0 : res.data
    ),
    __spreadValues(__spreadValues({
      enabled: Boolean(dropId) && Boolean(dropFlowId) && Boolean(itemId)
    }, MEDIUM_LIVED_QRY_CONFIG), queryOptions2 != null ? queryOptions2 : {})
  );
};
var useDropItemCountPeek = (dropId, queryOptions2) => {
  var _a;
  const { dropApi } = useApi();
  const apiResult = usePaginatedResourceQuery(
    ["DROP", dropId || "", "DROP_ITEM", "peek"],
    () => dropApi.betaReadDropItemControllerGetDropItemsForDrop(
      dropId,
      1,
      1,
      true,
      void 0,
      1
    ),
    __spreadValues(__spreadValues({
      enabled: Boolean(dropId),
      select: (data) => data == null ? void 0 : data.data
    }, MEDIUM_LIVED_QRY_CONFIG), queryOptions2 || {})
  );
  return {
    isPending: apiResult.isPending && Boolean(dropId),
    isError: apiResult.isError,
    dropItemCount: (_a = apiResult.data) == null ? void 0 : _a.total_results
  };
};
var useDeleteDrop = (drop) => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: (dropId) => dropApi.betaConfigureDropControllerDeleteDrop(dropId),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "DROP",
          resourceId: variables,
          data: { name: drop == null ? void 0 : drop.name }
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "DROP",
          error,
          resourceId: variables
        })
      );
    }
  });
  return mutation;
};
var useDeleteAllDropFlowItems = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: (dropFlowId) => dropApi.betaConfigureDropFlowControllerDeleteAllItemAssociationsFromDropFlow(dropFlowId),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "DROP_ITEM",
          resourceId: variables
        })
      );
    },
    onError: (error, variables, context) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "DROP_ITEM",
          error,
          resourceId: variables
        })
      );
    }
  });
  return mutation;
};
var useDeleteDropFlowItem = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: (dropItemId) => dropApi.betaConfigureDropItemControllerDeleteDropItem(dropItemId),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "DROP_ITEM",
          resourceId: variables
        })
      );
    },
    onError: (error, variables, context) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "DROP_ITEM",
          error,
          resourceId: variables
        })
      );
    }
  });
  return mutation;
};
var useDeleteDropFlow = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: (dropFlowId) => dropApi.betaConfigureDropFlowControllerDeleteDropFlow(dropFlowId),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "DROP_FLOW",
          resourceId: variables
        })
      );
    },
    onError: (error, variables, context) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "DROP_FLOW",
          error,
          resourceId: variables
        })
      );
    }
  });
  return mutation;
};
var useUpdateDropFlowItem = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: ({ dropItemId, dto }) => dropApi.betaConfigureDropItemControllerUpdateDropItem(dropItemId, dto),
    onSuccess: (variables) => {
      var _a;
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_ITEM",
          resourceId: (_a = variables == null ? void 0 : variables.data) == null ? void 0 : _a.id,
          data: variables == null ? void 0 : variables.data
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_ITEM",
          error,
          resourceId: variables == null ? void 0 : variables.dropItemId
        })
      );
    }
  });
  return mutation;
};
var useUpdateDropFlow = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: ({ dropFlowId, dto }) => {
      var _a;
      return (_a = dropApi.betaConfigureDropFlowControllerUpdateDropFlow(dropFlowId, dto)) == null ? void 0 : _a.then((res) => res == null ? void 0 : res.data);
    },
    onSuccess: (variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables == null ? void 0 : variables.id,
          data: variables
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          error,
          resourceId: variables == null ? void 0 : variables.dropFlowId
        })
      );
    }
  });
  return mutation;
};
var useCreateDropFlowItem = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: ({ dropFlowId, dto }) => dropApi.betaConfigureDropFlowControllerAddNewDropItemToDropFlow(dropFlowId, dto).then((res) => res == null ? void 0 : res.data),
    onSuccess: (data) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DROP_ITEM",
          resourceId: data == null ? void 0 : data.id,
          data
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DROP_ITEM",
          error,
          resourceId: variables == null ? void 0 : variables.dropFlowId
        })
      );
    }
  });
  return mutation;
};
var useUpdateDrop = (drop) => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: (updateDto) => dropApi.betaConfigureDropControllerUpdateDrop(drop == null ? void 0 : drop.id, updateDto),
    onSuccess: () => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP",
          resourceId: drop == null ? void 0 : drop.id,
          data: { name: drop == null ? void 0 : drop.name }
        })
      );
    },
    onError: (error) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP",
          error,
          resourceId: drop == null ? void 0 : drop.id
        })
      );
    }
  });
  return mutation;
};
var useDuplicateDrop = (queryOptions2) => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation(__spreadValues({
    mutationFn: ({
      dropId,
      duplicateOptions
    }) => dropApi.betaConfigureDropControllerCloneDropAndContent(dropId, duplicateOptions),
    onSuccess: (result, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DROP",
          data: { name: variables.duplicateOptions.name }
        })
      );
    },
    onError: (error) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DROP",
          error
        })
      );
    }
  }, queryOptions2 != null ? queryOptions2 : {}));
  return mutation;
};
var useCreateDropFlow = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: ({ dropId, dto }) => dropApi.betaConfigureDropFlowControllerAddNewDropFlowToDrop(dropId, dto).then((res) => res == null ? void 0 : res.data),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DROP_FLOW",
          resourceId: variables.dropId
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DROP_FLOW",
          error,
          resourceId: variables.dropId
        })
      );
    }
  });
  return mutation;
};
var useCancelDropFlow = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: (dropFlowId) => dropApi.betaOrchestrateDropFlowControllerUnScheduleDropFlowLaunch(dropFlowId, {}),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP",
          resourceId: variables
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP",
          error,
          resourceId: variables
        })
      );
    }
  });
  return mutation;
};
var useConvertDropToAdvancedDrop = (drop) => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const invalidateApiQuery = useInvalidateResourceQuery();
  const { data: allProjects, isPending: projectsLoading } = useAllProjects({
    enabled: Boolean(drop == null ? void 0 : drop.id)
  });
  const suggestedProject = useMemo2(() => {
    var _a;
    return (allProjects == null ? void 0 : allProjects.length) ? (_a = allProjects == null ? void 0 : allProjects.find((p) => p.name === "DEFAULT")) != null ? _a : allProjects[0] : void 0;
  }, [allProjects]);
  const {
    mutate: convertDrop,
    isPending: convertingDrop,
    isSuccess,
    reset
  } = useMutation({
    mutationFn: () => __async(void 0, null, function* () {
      if (!suggestedProject) {
        return dropApi.betaConfigureProjectControllerCreateNewProject({
          name: "DEFAULT",
          description: "Default project for converted drops"
        }).then((res) => {
          var _a;
          return dropApi.betaConfigureProjectControllerAddExistingDropToProject(
            (_a = res == null ? void 0 : res.data) == null ? void 0 : _a.id,
            drop == null ? void 0 : drop.id,
            {}
          );
        });
      } else
        return dropApi.betaConfigureProjectControllerAddExistingDropToProject(
          suggestedProject == null ? void 0 : suggestedProject.id,
          drop == null ? void 0 : drop.id,
          {}
        );
    }),
    onSuccess: () => {
      invalidateApiQuery("PROJECT");
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP",
          resourceId: drop == null ? void 0 : drop.id
        })
      );
    },
    onError: (error) => {
      invalidateApiQuery("PROJECT");
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP",
          error,
          resourceId: drop == null ? void 0 : drop.id
        })
      );
    }
  });
  return {
    convertDrop,
    convertingDrop,
    initializing: projectsLoading,
    convertedDrop: isSuccess,
    resetConvertDrop: reset
  };
};
var useDropFlowEmailTemplate = (dropId, dropFlowId, queryOptions2) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["DROP", dropId || "", "DROP_FLOW", dropFlowId || "", "EMAIL_TEMPLATE"],
    () => dropApi.betaReadDropFlowControllerGetEmailTemplateForType(dropFlowId, "CLAIM_YOUR_NFT").then((res) => res == null ? void 0 : res.data),
    __spreadValues(__spreadValues({
      enabled: Boolean(dropId) && Boolean(dropFlowId)
    }, MEDIUM_LIVED_QRY_CONFIG), queryOptions2 != null ? queryOptions2 : {})
  );
};
var useOrganizationSettings = (organizationId, queryOptions2) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["ORG_SETTINGS", organizationId || ""],
    () => dropApi.betaReadOrganizationSettingsControllerGetOrganizationSettingsFormOrgId(
      organizationId
    ).then((res) => res == null ? void 0 : res.data),
    __spreadValues(__spreadValues({}, LONG_LIVED_QRY_CONFIG), queryOptions2 != null ? queryOptions2 : {})
  );
};
var useExecuteDropFlow = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: ({ dropFlowId, costEstimateId }) => dropApi.betaOrchestrateDropFlowControllerOrchestrateDropFlow(dropFlowId, {
      cost_estimation_id: costEstimateId
    }),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables == null ? void 0 : variables.dropFlowId
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables == null ? void 0 : variables.dropFlowId,
          error
        })
      );
    }
  });
  return mutation;
};
var useCreateDropFlowConsumerUri = () => {
  const { dropApi } = useApi();
  return useMutation({
    mutationFn: (dropFlowId) => __async(void 0, null, function* () {
      var _a;
      const result = yield dropApi.betaConfigureDropFlowControllerGenerateDropFlowUrl(dropFlowId);
      const url = (_a = result == null ? void 0 : result.data) == null ? void 0 : _a.absolute;
      const coercedUrl = url ? (url == null ? void 0 : url.startsWith("http")) ? url : `https://${url}` : "";
      return coercedUrl;
    })
  });
};
var useDropFlowConsumerUri = (dropFlowId) => {
  const { dropApi } = useApi();
  const [result, setResult] = useState2({ isPending: false, isError: false });
  const {
    mutate: generateDropFlowAppUrl,
    isPending,
    isError
  } = useMutation({
    mutationFn: () => {
      return dropApi.betaConfigureDropFlowControllerGenerateDropFlowUrl(dropFlowId);
    },
    onSuccess: (result2) => {
      var _a;
      const url = (_a = result2 == null ? void 0 : result2.data) == null ? void 0 : _a.absolute;
      const coercedUrl = url ? (url == null ? void 0 : url.startsWith("http")) ? url : `https://${url}` : void 0;
      setResult({
        uri: coercedUrl,
        metamaskDeepLinkUri: (result2 == null ? void 0 : result2.data) && "metamask_deeplink" in (result2 == null ? void 0 : result2.data) ? result2 == null ? void 0 : result2.data["metamask_deeplink"] : void 0,
        isPending: false,
        isError: false
      });
    }
  });
  useEffect2(() => {
    setResult((old) => {
      return old ? __spreadProps(__spreadValues({}, old), { isPending, isError }) : { isPending, isError };
    });
  }, [isPending, isError]);
  useEffect2(() => {
    if (!dropFlowId)
      return;
    generateDropFlowAppUrl();
  }, [dropFlowId, generateDropFlowAppUrl]);
  return result;
};
var useAllDropFlowListings = (dropId, dropFlowId, queryOptions2) => {
  const { dropApi } = useApi();
  const apiResult = useAllPages_default(
    ["DROP", dropId || "", "DROP_FLOW", dropFlowId || "", "LISTING"],
    (cursor) => dropApi.betaReadDropListingControllerGetPaginatedDropListingsForDropFlow(
      dropFlowId,
      0,
      1e3,
      true,
      cursor || "",
      1e3
    ),
    __spreadValues(__spreadProps(__spreadValues({}, MEDIUM_LIVED_QRY_CONFIG), {
      enabled: Boolean(dropFlowId)
    }), queryOptions2 != null ? queryOptions2 : {})
  );
  return apiResult;
};
var useUpdateDropFlowListingMintWindow = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: ({ dropFlowId, dto }) => dropApi.betaDropListingControllerUpdateMintWindowForDropFlow(dropFlowId, dto).then((res) => res == null ? void 0 : res.data),
    onSuccess: (result, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables.dropFlowId,
          data: result
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables.dropFlowId,
          error
        })
      );
    }
  });
  return mutation;
};
var useUpdateDropFlowSlug = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: ({ dropFlowId, dto }) => dropApi.betaDropListingControllerUpdatSlugForDropFlow(dropFlowId, dto).then((res) => res == null ? void 0 : res.data),
    onSuccess: (result, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables.dropFlowId,
          data: result
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables.dropFlowId,
          error
        })
      );
    }
  });
  return mutation;
};
var useUpdateDropFlowListingPolicyType = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: ({ dropFlowId, dto }) => dropApi.betaDropListingControllerUpdatePolicyTypeForDropFlow(dropFlowId, dto),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables.dropFlowId
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables.dropFlowId,
          error
        })
      );
    }
  });
  return mutation;
};
var useCloseDropFlowListingMintWindow = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: ({ dropFlowId }) => dropApi.betaDropListingControllerCloseMintWindowForDropFlow(dropFlowId).then((res) => res == null ? void 0 : res.data),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables.dropFlowId
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables.dropFlowId,
          error
        })
      );
    }
  });
  return mutation;
};
var useUpdateDropFlowListingEligibility = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation({
    mutationFn: ({ dropFlowId, dto }) => dropApi.betaDropListingControllerUpdateEligibilityForDropFlow(dropFlowId, dto).then((res) => res == null ? void 0 : res.data),
    onSuccess: (result, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables.dropFlowId,
          data: result
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: variables.dropFlowId,
          error
        })
      );
    }
  });
  return mutation;
};
var useCreateQRCodeForUrl = (options) => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry();
  const mutation = useMutation(__spreadValues({
    mutationFn: ({
      qrCodeValue,
      asMetamaskDeepLink
    }) => dropApi.qrCodeControllerGenerateQRCodeFromUrl({
      text: qrCodeValue,
      as_metamask_deeplink: asMetamaskDeepLink
    }).then((res) => res == null ? void 0 : res.data),
    onSuccess: (result) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "QR_CODE",
          data: { url: result == null ? void 0 : result.uri }
        })
      );
    },
    onError: (error) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "QR_CODE",
          error
        })
      );
    }
  }, options != null ? options : {}));
  return mutation;
};
var useValidateDropFlowSlugAvailability = (dropFlowId) => {
  const { dropApi } = useApi();
  const _a = useMutation({
    mutationFn: (slug) => __async(void 0, null, function* () {
      if (slug && /^[a-zA-Z0-9._-]+$/.test(slug)) {
        return dropFlowId ? yield dropApi.betaConfigureDropFlowControllerGetDropFlowSlugAvailabilityExcludeExisting(
          dropFlowId,
          { slug }
        ).then((res) => {
          return { isAvailable: res == null ? void 0 : res.data.is_available };
        }).catch(() => {
          return { isAvailable: false };
        }) : yield dropApi.betaConfigureDropFlowControllerGetDropFlowSlugAvailability({
          slug
        }).then((res) => {
          return { isAvailable: res == null ? void 0 : res.data.is_available };
        }).catch(() => {
          return { isAvailable: false };
        });
      }
      return void 0;
    }),
    onError: () => {
      return void 0;
    }
  }), { mutate, mutateAsync } = _a, rest = __objRest(_a, ["mutate", "mutateAsync"]);
  const mutateAsyncDebounced = useMemo2(() => {
    return debounce(mutateAsync, 750);
  }, [mutateAsync]);
  return __spreadValues({ mutate, mutateAsync: mutateAsyncDebounced }, rest);
};

// src/hooks/distributions.ts
import { queryOptions, useInfiniteQuery as useInfiniteQuery3, useMutation as useMutation2 } from "@tanstack/react-query";
import { usePluginRegistry as usePluginRegistry2 } from "plugin-system";

// src/helpers.ts
import { capitalize, mapKeys, snakeCase } from "lodash";
import { z } from "zod";
function getCoreApiErrorMessage(error) {
  var _a, _b, _c, _d, _e, _f, _g;
  let errorMessage = (_g = (_f = (_b = (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.message) != null ? _f : (_e = (_d = (_c = error == null ? void 0 : error.response) == null ? void 0 : _c.data) == null ? void 0 : _d.error) == null ? void 0 : _e.detail) != null ? _g : "";
  if (errorMessage && errorMessage.length > 300) {
    errorMessage = errorMessage.substring(0, 50) + "..." + errorMessage.substring(errorMessage.length - 50, errorMessage.length);
  }
  return errorMessage;
}
function formatKeysForApi(obj) {
  return mapKeys(obj, (value, key) => snakeCase(key));
}
var DUPLICATE_NAME_ERROR_MESSAGE = (item) => capitalize(item) + " with the current name already exists. Please select a unique name.";
function isEmailAddress(recipient) {
  const emailValidator = z.string().email();
  if (recipient && emailValidator.safeParse(recipient).success) {
    return true;
  } else {
    return false;
  }
}
function getApiErrorMessage(error) {
  if (!error)
    return void 0;
  return (typeof error === "string" ? error : "") || getCoreApiErrorMessage(error);
}
function getCoreApiErrorCode(error) {
  var _a, _b, _c, _d;
  return (_d = (_c = (_b = (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.error) == null ? void 0 : _c.code) != null ? _d : "";
}
function getCoreApiErrorStatus(error) {
  var _a, _b, _c, _d;
  return (_d = (_c = (_b = (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.error) == null ? void 0 : _c.status) != null ? _d : "";
}

// src/hooks/distributions.ts
var usePaginatedDropFlowDistributions = (dropId, dropFlowId, options) => {
  const { dropApi } = useApi();
  return useInfiniteQuery3(__spreadValues(__spreadValues({
    queryKey: ["DROP", dropId || "", "DROP_FLOW", dropFlowId || "", "DISTRIBUTION"],
    queryFn: ({ pageParam: cursor }) => dropApi.betaReadItemDistributionControllerGetAllItemDistributionsForDropFlow(
      dropFlowId || "",
      true,
      true,
      void 0,
      void 0,
      true,
      cursor,
      options.limit,
      options.status,
      void 0,
      !(options == null ? void 0 : options.address) || isEmailAddress(options == null ? void 0 : options.address) ? void 0 : options.address,
      (options == null ? void 0 : options.address) && isEmailAddress(options == null ? void 0 : options.address) ? options.address : void 0,
      void 0,
      void 0,
      void 0
    ).then((res) => res == null ? void 0 : res.data),
    initialPageParam: void 0,
    getNextPageParam: (lastPage) => {
      const castedLastPage = lastPage;
      return castedLastPage.has_more ? castedLastPage.cursor : void 0;
    },
    enabled: Boolean(dropId) && Boolean(dropFlowId)
  }, MEDIUM_LIVED_QRY_CONFIG), queryOptions || {}));
};
var useAllDropFlowDistributionsForAddress = (dropId, dropFlowId, options) => {
  var _a;
  const { dropApi } = useApi();
  return useAllPages_default(
    ["DROP", dropId || "", "DROP_FLOW", dropFlowId || "", "DISTRIBUTION"],
    (cursor) => dropApi.betaReadItemDistributionControllerGetAllItemDistributionsForDropFlow(
      dropFlowId || "",
      true,
      true,
      void 0,
      void 0,
      true,
      cursor,
      void 0,
      options == null ? void 0 : options.status,
      void 0,
      !(options == null ? void 0 : options.address) || isEmailAddress(options == null ? void 0 : options.address) ? void 0 : options.address,
      (options == null ? void 0 : options.address) && isEmailAddress(options == null ? void 0 : options.address) ? options.address : void 0,
      void 0,
      void 0,
      void 0
    ),
    __spreadValues({
      enabled: Boolean(dropId) && Boolean(dropFlowId),
      refetchInterval: (_a = options == null ? void 0 : options.refetchInterval) != null ? _a : MEDIUM_LIVED_QRY_REFETCH_INTERVAL
    }, options != null ? options : {})
  );
};
var useAllDropFlowDistributionsForWallet = (dropId, dropFlowId, wallet, options) => {
  const { dropApi } = useApi();
  return useAllPages_default(
    ["DROP", dropId || "", "DROP_FLOW", dropFlowId || "", "DISTRIBUTION", wallet || ""],
    (cursor) => dropApi.betaReadItemDistributionControllerGetAllItemDistributionsForDropFlow(
      dropFlowId != null ? dropFlowId : "",
      true,
      true,
      0,
      1e4,
      true,
      cursor || "",
      1e4,
      void 0,
      void 0,
      wallet
    ),
    __spreadValues({
      enabled: Boolean(dropId) && Boolean(dropFlowId) && Boolean(wallet),
      refetchInterval: SHORT_LIVED_QRY_REFETCH_INTERVAL
    }, options != null ? options : {})
  );
};
var useCreateAdditionalClaim = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry2();
  return useMutation2({
    mutationFn: ({
      dropFlowId,
      payload
    }) => dropApi.betaItemDistributionControllerCreateAdditionalClaimForDropFlow(dropFlowId, payload).then((res) => res == null ? void 0 : res.data),
    onSuccess: () => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DISTRIBUTION"
        })
      );
    },
    onError: (error) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DISTRIBUTION",
          error
        })
      );
    }
  });
};
var useCreateAdditionalIrlClaim = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry2();
  return useMutation2({
    mutationFn: ({
      dropFlowId,
      payload
    }) => dropApi.betaItemDistributionControllerCreateAdditionalIRLClaimForDropFlow(dropFlowId, payload).then((res) => res == null ? void 0 : res.data),
    onSuccess: (_) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DISTRIBUTION"
        })
      );
    },
    onError: (error) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DISTRIBUTION",
          error
        })
      );
    }
  });
};
var useCreateAdditionalTransfer = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry2();
  return useMutation2({
    mutationFn: ({
      dropFlowId,
      payload
    }) => dropApi.betaItemDistributionControllerCreateAdditionalTransferForDropFlow(dropFlowId, payload).then((res) => res == null ? void 0 : res.data),
    onSuccess: () => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DISTRIBUTION"
        })
      );
    },
    onError: (error) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "DISTRIBUTION",
          error
        })
      );
    }
  });
};
var useCreateAdditionalClaimEmail = () => {
  const { dropApi } = useApi();
  return useMutation2({
    mutationFn: ({
      itemDistributionId,
      updateExpiration = true
    }) => dropApi.betaItemDistributionControllerResendEmail(itemDistributionId, updateExpiration).then((res) => res == null ? void 0 : res.data)
  });
};
var useAddAdditionalDistribution = () => {
  const additionalClaimMutation = useCreateAdditionalClaim();
  const additionalTransferMutation = useCreateAdditionalTransfer();
  return (dropFlowId, payload, options = {}) => {
    if ("email" in payload) {
      additionalClaimMutation.mutate({ dropFlowId, payload }, options);
    } else {
      additionalTransferMutation.mutate({ dropFlowId, payload }, options);
    }
  };
};

// src/hooks/allowlist.ts
import { useMutation as useMutation3 } from "@tanstack/react-query";
import { usePluginRegistry as usePluginRegistry3 } from "plugin-system";
import { useEffect as useEffect3, useState as useState3 } from "react";
var useRemoveFromAllowlist = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry3();
  return useMutation3({
    mutationFn: (entry) => dropApi.betaConfigureDropFlowControllerDeleteAllowListEntry(entry.id),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "ALLOW_LIST",
          resourceId: variables.id
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "ALLOW_LIST",
          error,
          resourceId: variables.id
        })
      );
    }
  });
};
var useAddToAllowlist = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry3();
  return useMutation3({
    mutationFn: ({ dropFlowId, dto }) => dropApi.betaConfigureDropFlowControllerAddAllowListEntry(dropFlowId, dto).then((res) => res.data),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "ALLOW_LIST",
          resourceId: variables.dropFlowId
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "ALLOW_LIST",
          error,
          resourceId: variables.dropFlowId
        })
      );
    }
  });
};
var useUpdateAllowlistEntry = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry3();
  return useMutation3({
    mutationFn: ({ entryToUpdate, payload }) => dropApi.betaConfigureDropFlowControllerUpdateAllowListEntry(
      entryToUpdate.id,
      payload
    ),
    onSuccess: (_, { entryToUpdate }) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "ALLOW_LIST",
          resourceId: entryToUpdate.id
        })
      );
    },
    onError: (error, { entryToUpdate }) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "ALLOW_LIST",
          resourceId: entryToUpdate.id,
          error
        })
      );
    }
  });
};
var useBulkReplaceAllowlist = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry3();
  return useMutation3({
    mutationFn: ({ dropFlowId, payload }) => {
      return dropApi.betaConfigureDropFlowControllerBulkReplaceAllowList(dropFlowId, payload);
    },
    onSuccess: (_, { dropFlowId }) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: dropFlowId
        })
      );
    },
    onError: (error, { dropFlowId }) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "DROP_FLOW",
          resourceId: dropFlowId,
          error
        })
      );
    }
  });
};
var useFullDropFlowAllowList = (dropId, dropFlowId, queryOptions2) => {
  const { dropApi } = useApi();
  return useAllPages_default(
    ["DROP", dropId || "", "DROP_FLOW", dropFlowId || "", "ALLOW_LIST", "ALL"],
    (cursor) => dropApi.betaReadDropFlowControllerGetPaginatedAllowListForDropFlow(
      dropFlowId != null ? dropFlowId : "",
      0,
      1e4,
      true,
      cursor || "",
      1e4
    ),
    __spreadValues(__spreadValues({
      enabled: Boolean(dropId) && Boolean(dropFlowId)
    }, MEDIUM_LIVED_QRY_CONFIG), queryOptions2 || {})
  );
};
var useCheckDropFlowAllowListPresence = (dropId, dropFlowId, wallet, queryOptions2) => {
  const { dropApi } = useApi();
  const [result, setResult] = useState3({ isPending: true, isPresent: void 0, isError: false });
  const { isPending, data, isError } = useResourceQuery(
    ["DROP", dropId || "", "DROP_FLOW", dropFlowId || "", "ALLOW_LIST", wallet || ""],
    () => {
      var _a;
      return (_a = dropApi.betaReadDropFlowControllerGetPaginatedAllowListForDropFlow(
        dropFlowId != null ? dropFlowId : "",
        0,
        1,
        true,
        void 0,
        1,
        void 0,
        wallet
      )) == null ? void 0 : _a.then((res) => res == null ? void 0 : res.data);
    },
    __spreadValues({
      enabled: Boolean(dropId) && Boolean(dropFlowId) && Boolean(wallet),
      gcTime: 0,
      staleTime: 0
    }, queryOptions2 != null ? queryOptions2 : {})
  );
  useEffect3(() => {
    setResult({
      isPending: isPending || (data == null ? void 0 : data.cursor) === void 0 || !Boolean(wallet),
      isPresent: (data == null ? void 0 : data.cursor) !== void 0 && Boolean(data == null ? void 0 : data.total_results) && Boolean(wallet),
      isError
    });
  }, [isPending, data, isError, wallet]);
  return result;
};

// src/hooks/snapshot.ts
import { useEffect as useEffect4, useMemo as useMemo3, useState as useState4 } from "react";
import orderBy2 from "lodash/orderBy";
import { useMutation as useMutation4 } from "@tanstack/react-query";
import { usePluginRegistry as usePluginRegistry4 } from "plugin-system";

// src/hooks/collection.ts
var useCollection = (collectionId) => {
  const { coreApi } = useApi();
  return useResourceQuery(
    ["COLLECTION", collectionId],
    () => coreApi.collectionsCollectionIdGet(collectionId).then((res) => res == null ? void 0 : res.data),
    {
      enabled: !!collectionId
    }
  );
};

// src/hooks/item.ts
var useCollectionItems = ({ collectionId, tokenIds }, { pageSize = 10, cursor = "" }, queryOptions2) => {
  var _a;
  const { coreApi } = useApi();
  return usePaginatedResourceQuery(
    ["COLLECTION", collectionId, "ITEM", pageSize, cursor, (_a = tokenIds == null ? void 0 : tokenIds.join(",")) != null ? _a : ""],
    () => coreApi.itemsGet(collectionId, void 0, cursor, pageSize, tokenIds).then((res) => res == null ? void 0 : res.data),
    __spreadValues(__spreadValues({
      enabled: !!collectionId
    }, LONG_LIVED_QRY_CONFIG), queryOptions2 || {})
  );
};
var useSingleCollectionItem = (collectionId, tokenIds, queryOptions2) => {
  return useCollectionItems(
    { collectionId, tokenIds },
    {
      pageSize: 1
    },
    __spreadValues({
      select: (data) => {
        if (data.total_results === 1) {
          return data.results[0];
        }
        return null;
      }
    }, queryOptions2 || {})
  );
};

// src/hooks/snapshot.ts
var useAllSnapshots = (sort) => {
  const { coreApi } = useApi();
  const results = useAllPages_default(
    ["SNAPSHOT", "ALL"],
    (cursor) => coreApi.snapshotsGet("DESC", cursor, 100),
    __spreadValues({}, MEDIUM_LIVED_QRY_CONFIG)
  );
  const sortedResults = useMemo3(() => {
    if (!results.data || !sort)
      return results;
    return __spreadProps(__spreadValues({}, results), {
      data: orderBy2(results.data, sort.key, sort.direction)
    });
  }, [results == null ? void 0 : results.data, results == null ? void 0 : results.isPending, sort]);
  return sortedResults;
};
var useAllSnapshotHoldersByAddress = (snapshotId, sort) => {
  const { coreApi } = useApi();
  const results = useAllPages_default(
    ["SNAPSHOT", snapshotId || "", "SNAPSHOT_HOLDER", "PER_ADDRESS", "ALL"],
    (cursor) => coreApi.snapshotsSnapshotIdHoldersGet(
      snapshotId,
      "DESC",
      cursor,
      CORE_HIGH_API_PAGINATION_LIMIT,
      "PER_ADDRESS"
    ),
    __spreadValues({
      enabled: Boolean(snapshotId)
    }, MEDIUM_LIVED_QRY_CONFIG)
  );
  const sortedResults = useMemo3(() => {
    if (!sort || !results.data)
      return results;
    return __spreadProps(__spreadValues({}, results), {
      data: orderBy2(results.data, sort.key, sort.direction)
    });
  }, [results == null ? void 0 : results.data, results == null ? void 0 : results.isPending]);
  return sortedResults;
};
var useCheckSnapshotPresence = (snapshotId, wallet, queryOptions2) => {
  const { coreApi } = useApi();
  const [result, setResult] = useState4({ isPending: true, isPresent: void 0, isError: false });
  const { isPending, data, isError } = useResourceQuery(
    ["SNAPSHOT", snapshotId || "", "SNAPSHOT_HOLDER", "PER_ADDRESS", wallet || ""],
    () => coreApi.snapshotsSnapshotIdHoldersGet(
      snapshotId,
      "DESC",
      void 0,
      1,
      "PER_ADDRESS",
      [wallet],
      void 0
    ).then((res) => res == null ? void 0 : res.data),
    __spreadValues({
      enabled: Boolean(snapshotId) && Boolean(wallet),
      gcTime: 0,
      staleTime: 0
    }, queryOptions2 != null ? queryOptions2 : {})
  );
  useEffect4(() => {
    setResult({
      isPending: isPending || (data == null ? void 0 : data.cursor) === void 0 || !Boolean(wallet),
      isPresent: (data == null ? void 0 : data.cursor) !== void 0 && Boolean(data == null ? void 0 : data.total_results) && Boolean(wallet),
      isError
    });
  }, [isPending, data, isError, wallet]);
  return result;
};
var useAllSnapshotHoldersByToken = (snapshotId) => {
  const { coreApi } = useApi();
  return useAllPages_default(
    ["SNAPSHOT", snapshotId || "", "SNAPSHOT_HOLDER", "PER_TOKEN", "ALL"],
    (cursor) => coreApi.snapshotsSnapshotIdHoldersGet(
      snapshotId,
      "DESC",
      cursor,
      CORE_HIGH_API_PAGINATION_LIMIT,
      "PER_TOKEN"
    ),
    __spreadValues({
      enabled: Boolean(snapshotId)
    }, MEDIUM_LIVED_QRY_CONFIG)
  );
};
var useSnapshot = (snapshotId) => {
  const { coreApi } = useApi();
  return useResourceQuery(
    ["SNAPSHOT", snapshotId || ""],
    () => coreApi.snapshotsSnapshotIdGet(snapshotId).then((res) => res == null ? void 0 : res.data),
    __spreadProps(__spreadValues({}, MEDIUM_LIVED_QRY_CONFIG), {
      enabled: !!snapshotId
    })
  );
};
var useShowSnapshotImage = (snapshotId) => {
  var _a, _b;
  const { data: snapshot } = useSnapshot(snapshotId);
  const { data: collection } = useCollection((snapshot == null ? void 0 : snapshot.collection_id) || "");
  const { data: item } = useSingleCollectionItem(
    (snapshot == null ? void 0 : snapshot.collection_id) || "",
    // @ts-ignore
    (_b = (_a = snapshot == null ? void 0 : snapshot.criteria) == null ? void 0 : _a.token_ids) != null ? _b : []
  );
  const showImage = useMemo3(() => {
    var _a2, _b2, _c, _d, _e, _f, _g, _h, _i, _j;
    if (!(snapshot == null ? void 0 : snapshot.collection_id))
      return false;
    if (((_c = (_b2 = (_a2 = snapshot == null ? void 0 : snapshot.criteria) == null ? void 0 : _a2.token_ids) == null ? void 0 : _b2.length) != null ? _c : 0) === 1)
      return true;
    const collectionImage = (_g = (_f = (_d = collection == null ? void 0 : collection.media) == null ? void 0 : _d.thumbnail_image_url) != null ? _f : (_e = collection == null ? void 0 : collection.media) == null ? void 0 : _e.header_image_url) != null ? _g : collection == null ? void 0 : collection.image_url;
    if (collectionImage)
      return true;
    const itemImage = ((_i = (_h = item == null ? void 0 : item.media) == null ? void 0 : _h.image) == null ? void 0 : _i.thumb) || (item == null ? void 0 : item.attributes) && "image_url" in item.attributes && ((_j = item == null ? void 0 : item.attributes) == null ? void 0 : _j.image_url);
    if (itemImage)
      return true;
    return false;
  }, [snapshot, collection, item]);
  return showImage;
};
var useEditSnapshot = (snapshot, queryOptions2) => {
  const { coreApi } = useApi();
  const pluginRegistry = usePluginRegistry4();
  const mutation = useMutation4(__spreadValues({
    mutationFn: ({ snapshotId, body }) => coreApi.snapshotsSnapshotIdPatch(snapshotId, body, {}),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "SNAPSHOT",
          resourceId: variables.snapshotId,
          data: { name: snapshot == null ? void 0 : snapshot.name }
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "SNAPSHOT",
          error,
          resourceId: variables.snapshotId
        })
      );
    },
    enabled: !!snapshot
  }, queryOptions2 != null ? queryOptions2 : {}));
  return mutation;
};
var useDeleteSnapshot = (snapshot) => {
  const { coreApi } = useApi();
  const pluginRegistry = usePluginRegistry4();
  const mutation = useMutation4({
    mutationFn: ({ snapshotId }) => coreApi.snapshotsSnapshotIdDelete(snapshotId),
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "SNAPSHOT",
          resourceId: variables.snapshotId,
          data: { name: snapshot == null ? void 0 : snapshot.name }
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "SNAPSHOT",
          error,
          resourceId: variables.snapshotId
        })
      );
    }
  });
  return mutation;
};
var useExportAllSnapshotHoldersToCSV = () => {
  const { coreApi } = useApi();
  const mutation = useMutation4({
    mutationFn: ({
      snapshotId,
      outputType
    }) => coreApi.snapshotsSnapshotIdHoldersCsvGet(snapshotId, outputType)
  });
  return mutation;
};
var useAllSnapshotHolderTokens = (snapshotId, collector, sort) => {
  const { dropApi } = useApi();
  const results = useAllPages_default(
    ["SNAPSHOT", snapshotId || "", "SNAPSHOT_HOLDER", collector || ""],
    (cursor) => dropApi.augmentedSnapshotControllerGetSnapshotHolderDetailedTokensPage(
      snapshotId,
      collector,
      cursor || "",
      CORE_HIGH_API_PAGINATION_LIMIT
    ),
    __spreadValues({
      enabled: Boolean(snapshotId) && Boolean(collector)
    }, MEDIUM_LIVED_QRY_CONFIG)
  );
  const sortedResults = useMemo3(() => {
    if (!sort || !(results == null ? void 0 : results.data)) {
      return results;
    }
    return __spreadProps(__spreadValues({}, results), {
      data: orderBy2(results.data, sort.key, sort.direction)
    });
  }, [results == null ? void 0 : results.data, results == null ? void 0 : results.isPending]);
  return sortedResults;
};

// src/hooks/audience.ts
import { usePluginRegistry as usePluginRegistry5 } from "plugin-system";
import { useMutation as useMutation5 } from "@tanstack/react-query";
var useAllAudienceLists = () => {
  const { dropApi } = useApi();
  const results = useAllPages_default(
    ["AUDIENCE_LIST", "ALL"],
    (cursor) => dropApi.betaReadAudienceListControllerGetPaginatedAudienceListForOrganization(
      0,
      100,
      true,
      cursor || "",
      100
    ),
    __spreadValues({}, MEDIUM_LIVED_QRY_CONFIG)
  );
  return results;
};
var useAudienceList = (audienceListId) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["AUDIENCE_LIST", audienceListId != null ? audienceListId : ""],
    () => dropApi.betaReadAudienceListControllerGetAudienceList(audienceListId).then(
      (res) => res == null ? void 0 : res.data
    ),
    __spreadProps(__spreadValues({}, MEDIUM_LIVED_QRY_CONFIG), {
      enabled: !!audienceListId
    })
  );
};
var useAllAudienceListMembers = (audienceListId) => {
  const { dropApi } = useApi();
  const results = useAllPages_default(
    ["AUDIENCE_LIST", audienceListId != null ? audienceListId : "", "AUDIENCE_LIST_MEMBER", "ALL"],
    (cursor) => dropApi.betaReadAudienceListControllerGetPaginatedAudienceListMembers(
      audienceListId,
      0,
      100,
      true,
      cursor || "",
      100
    ),
    __spreadValues({
      enabled: Boolean(audienceListId)
    }, MEDIUM_LIVED_QRY_CONFIG)
  );
  return results;
};
var useDeleteAudienceList = (audience) => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry5();
  const mutation = useMutation5({
    mutationFn: ({ audienceListId }) => dropApi.betaAudienceListControllerDeleteAudienceList(audienceListId),
    onSuccess: (result, variables, context) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "AUDIENCE_LIST",
          resourceId: variables.audienceListId,
          data: { name: audience == null ? void 0 : audience.name }
        })
      );
    },
    onError: (error, variables, context) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "AUDIENCE_LIST",
          error,
          resourceId: variables.audienceListId
        })
      );
    }
  });
  return mutation;
};
var useAddAudienceListMember = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry5();
  const mutation = useMutation5({
    mutationFn: ({
      audienceListId,
      dto
    }) => dropApi.betaAudienceListControllerAddNewMemberToAudienceList(audienceListId, dto),
    onSuccess: (result, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "AUDIENCE_LIST_MEMBER",
          resourceId: variables.audienceListId,
          data: { name: result }
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "CREATE",
          resource: "AUDIENCE_LIST_MEMBER",
          error,
          resourceId: variables.audienceListId
        })
      );
    }
  });
  return mutation;
};

// src/hooks/payments.ts
import { useMutation as useMutation6 } from "@tanstack/react-query";
var useBalance = (orgId, queryOptions2) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["CREATOR_BALANCE", orgId],
    () => dropApi.betaPaymentControllerGetCreatorBalance().then((res) => res == null ? void 0 : res.data),
    __spreadProps(__spreadValues(__spreadValues({}, SHORT_LIVED_QRY_CONFIG), queryOptions2 != null ? queryOptions2 : {}), {
      enabled: !!orgId
    })
  );
};
var useCreateStripePaymentIntent = () => {
  const { betaApi } = useApi();
  return useMutation6({
    mutationFn: (payload) => {
      return betaApi.paymentStripePost(payload);
    }
  });
};
var useDropCostEstimate = (dropId, paymentMethod, queryOptions2) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["DROP", dropId, "COST_ESTIMATE"],
    () => dropApi.betaPaymentControllerCreateDropCostEstimation(dropId, {
      payment_method: paymentMethod
    }).then((res) => res == null ? void 0 : res.data),
    __spreadProps(__spreadValues({
      staleTime: 0
    }, queryOptions2 != null ? queryOptions2 : {}), {
      enabled: !!dropId
    })
  );
};
var useDropFlowCostEstimate = (dropId, dropFlowId, paymentMethod, queryOptions2) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["DROP", dropId || "", "DROP_FLOW", dropFlowId || "", "COST_ESTIMATE"],
    () => dropApi.betaPaymentControllerCreateDropFlowCostEstimation_2(dropFlowId, {
      payment_method: paymentMethod
    }).then((res) => res == null ? void 0 : res.data),
    __spreadProps(__spreadValues({
      staleTime: 0
    }, queryOptions2 != null ? queryOptions2 : {}), {
      enabled: Boolean(dropId) && Boolean(dropFlowId)
    })
  );
};
var useStatelessDropCostEstimate = (body) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["COST_ESTIMATE", body],
    () => dropApi.betaPaymentControllerCreateStatelessDistributionCostEstimation(body).then((res) => res == null ? void 0 : res.data),
    {
      staleTime: 0,
      // Don't give us cached results - we want to get a new cost estimate if needed
      enabled: true
    }
  );
};
var useAllDropCostEstimations = (dropId) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["DROP", dropId || "", "COST_ESTIMATE", "ALL"],
    () => dropApi.betaPaymentControllerGetAllCostEstimationsForDrop(dropId).then((res) => res == null ? void 0 : res.data),
    {
      staleTime: 0,
      // Don't give us cached results - we want to get a new cost estimate if needed
      enabled: Boolean(dropId)
    }
  );
};
var useDropEffectiveCosts = (dropId, queryOptions2) => {
  const { dropApi } = useApi();
  return useResourceQuery(
    ["DROP", dropId || "", "EFFECTIVE_COSTS"],
    () => dropApi.betaPaymentControllerGetEffectiveDropCost(dropId).then((res) => res == null ? void 0 : res.data),
    __spreadValues({
      staleTime: 0,
      // Don't give us cached results - we want to get most up to date cost actuals
      enabled: Boolean(dropId)
    }, queryOptions2 != null ? queryOptions2 : {})
  );
};

// src/hooks/organization.ts
import { useMutation as useMutation7, useQueryClient as useQueryClient2 } from "@tanstack/react-query";
import { usePluginRegistry as usePluginRegistry6 } from "plugin-system";
import debounce2 from "debounce-async";
import { useMemo as useMemo4 } from "react";
var useOrg = (organizationId) => {
  const { coreApi } = useApi();
  return useResourceQuery(
    ["ORGANIZATION", organizationId],
    () => coreApi.organizationsOrganizationIdGet(organizationId).then((res) => res == null ? void 0 : res.data),
    {
      enabled: Boolean(organizationId)
    }
  );
};
var useOrgProfile = (organizationId, options = {}) => {
  const { betaApi } = useApi();
  return useResourceQuery(
    ["ORGANIZATION", organizationId, "ORGANIZATION_PROFILE"],
    () => betaApi.organizationsProfileGet(options).then((res) => res == null ? void 0 : res.data),
    __spreadProps(__spreadValues({
      enabled: Boolean(organizationId)
    }, LONG_LIVED_QRY_CONFIG), {
      retry: 3,
      retryDelay: 1e3
    })
  );
};
var useEditOrg = (organizationId) => {
  const { coreApi } = useApi();
  const pluginRegistry = usePluginRegistry6();
  return useMutation7({
    mutationFn: (data) => coreApi.organizationsOrganizationIdPatch(organizationId, data),
    onSuccess: () => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "ORGANIZATION",
          resourceId: organizationId
        })
      );
    },
    onError: (error) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "ORGANIZATION",
          resourceId: organizationId,
          error
        })
      );
    }
  });
};
var useEditOrgProfile = (organizationId, options = {}) => {
  const { betaApi } = useApi();
  const pluginRegistry = usePluginRegistry6();
  const queryClient = useQueryClient2();
  const invalidate = useInvalidateResourceQuery();
  return useMutation7({
    mutationFn: (data) => betaApi.organizationsProfilePatch(data, options),
    onSuccess: (data) => {
      invalidate("ORGANIZATION");
      invalidate("ORGANIZATION_PROFILE");
      const mutationCache = queryClient.getMutationCache();
      const cachedProfileLink = mutationCache.find({
        mutationKey: ["ORGANIZATION", organizationId || "", "PROFILE_URI"]
      });
      if (cachedProfileLink) {
        mutationCache.remove(cachedProfileLink);
      }
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "ORGANIZATION_PROFILE",
          resourceId: organizationId,
          data
        })
      );
    },
    onError: (error) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "ORGANIZATION_PROFILE",
          resourceId: organizationId,
          error
        })
      );
    }
  });
};
var useEditOrgNotificationSettings = () => {
  const { betaApi } = useApi();
  const pluginRegistry = usePluginRegistry6();
  const invalidate = useInvalidateResourceQuery();
  return useMutation7({
    mutationFn: (data) => betaApi.settingsEmailNotificationPatch(data),
    onSuccess: (data) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "NOTIFICATION_SETTING",
          data
        })
      );
      invalidate("NOTIFICATION_SETTING");
    },
    onError: (error) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "NOTIFICATION_SETTING",
          error
        })
      );
    }
  });
};
var useValidateOrgSlugAvailability = () => {
  const { betaApi } = useApi();
  const _a = useMutation7({
    mutationFn: (slug) => __async(void 0, null, function* () {
      if (slug && /^[a-zA-Z0-9._-]+$/.test(slug)) {
        return yield betaApi.organizationsProfileSlugGet(slug).then((res) => {
          return { isAvailable: res == null ? void 0 : res.data.is_available };
        });
      }
      return void 0;
    }),
    onError: () => {
      return void 0;
    }
  }), { mutate, mutateAsync } = _a, rest = __objRest(_a, ["mutate", "mutateAsync"]);
  const mutateAsyncDebounced = useMemo4(() => {
    return debounce2(mutateAsync, 750);
  }, [mutateAsync]);
  return __spreadValues({ mutate, mutateAsync: mutateAsyncDebounced }, rest);
};

// src/hooks/listing.ts
import { useInfiniteQuery as useInfiniteQuery4, useMutation as useMutation8 } from "@tanstack/react-query";
import { usePluginRegistry as usePluginRegistry7 } from "plugin-system";
import { useEffect as useEffect5, useState as useState5 } from "react";

// src/sdks/sdk-platform-beta.ts
import globalAxios2 from "axios";

// src/sdks/base.ts
import globalAxios from "axios";

// src/sdks/sdk-platform-beta.ts
var AllowListGetPaginatedInputAddressTypeEnum = {
  Eth: "ETH",
  Email: "EMAIL"
};
var ListingsListingIdAllowListGetOrderDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC",
  Null: "null"
};
var ListingsListingIdAllowListGetAddressTypeEnum = {
  Eth: "ETH",
  Email: "EMAIL",
  Null: "null"
};

// src/hooks/listing.ts
var usePaginatedListingAllowlist = (dropId, dropFlowId, listingId, {
  order_direction = ListingsListingIdAllowListGetOrderDirectionEnum.Asc,
  limit = 10,
  address_type = ListingsListingIdAllowListGetAddressTypeEnum.Eth,
  eth_address,
  email_address
}, queryOptions2) => {
  const { betaApi } = useApi();
  return useInfiniteQuery4(
    __spreadValues(__spreadValues({
      enabled: Boolean(dropId) && Boolean(dropFlowId) && Boolean(listingId),
      queryKey: ["DROP", dropId, "DROP_FLOW", dropFlowId, "LISTING", listingId, "ALLOW_LIST"],
      queryFn: ({ pageParam: cursor }) => betaApi.listingsListingIdAllowListGet(
        listingId,
        order_direction,
        cursor,
        limit,
        address_type,
        eth_address,
        email_address
      ).then((res) => res == null ? void 0 : res.data),
      initialPageParam: void 0,
      getNextPageParam: (lastPage) => {
        const castedLastPage = lastPage;
        return castedLastPage.has_more ? castedLastPage.cursor : void 0;
      }
    }, MEDIUM_LIVED_QRY_CONFIG), queryOptions2 || {})
  );
};
var useCheckListingAllowlistPresence = (dropId, dropFlowId, listingId, wallet, queryOptions2) => {
  const { betaApi } = useApi();
  const [result, setResult] = useState5({ isPending: true, isPresent: void 0, isError: false });
  const { isPending, data, isError } = useResourceQuery(
    [
      "DROP",
      dropId || "",
      "DROP_FLOW",
      dropFlowId || "",
      "LISTING",
      listingId || "",
      "ALLOW_LIST",
      wallet || ""
    ],
    () => betaApi.listingsListingIdAllowListGet(
      listingId,
      void 0,
      void 0,
      1,
      ListingsListingIdAllowListGetAddressTypeEnum.Eth,
      [wallet],
      void 0,
      void 0
    ).then((res) => res == null ? void 0 : res.data),
    __spreadValues({
      enabled: Boolean(dropId) && Boolean(dropFlowId) && Boolean(listingId) && Boolean(wallet),
      gcTime: 0,
      staleTime: 0
    }, queryOptions2 != null ? queryOptions2 : {})
  );
  useEffect5(() => {
    setResult({
      isPending: isPending || (data == null ? void 0 : data.cursor) === void 0 || !Boolean(wallet),
      isPresent: (data == null ? void 0 : data.cursor) !== void 0 && Boolean(data == null ? void 0 : data.total_results) && Boolean(wallet),
      isError
    });
  }, [isPending, data, isError, wallet]);
  return result;
};
var useDeleteListingAllowlist = () => {
  const { betaApi } = useApi();
  const pluginRegistry = usePluginRegistry7();
  return useMutation8({
    mutationFn: ({ listingId }) => {
      return betaApi.listingsListingIdAllowListDelete(listingId);
    },
    onSuccess: (_, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "ALLOW_LIST",
          resourceId: variables == null ? void 0 : variables.listingId
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "DELETE",
          resource: "ALLOW_LIST",
          error,
          resourceId: variables.listingId
        })
      );
    }
  });
};
var useEditListingAllowlist = () => {
  const { betaApi } = useApi();
  const pluginRegistry = usePluginRegistry7();
  return useMutation8({
    mutationFn: ({
      listingId,
      action,
      addresses,
      addressType
    }) => {
      const allowListEditInput = {
        action,
        email_addresses: addressType === AllowListGetPaginatedInputAddressTypeEnum.Email ? addresses : void 0,
        eth_addresses: addressType === AllowListGetPaginatedInputAddressTypeEnum.Eth ? addresses : void 0
      };
      return betaApi.listingsListingIdAllowListPost(listingId, allowListEditInput);
    },
    onSuccess: (result) => {
      var _a;
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "ALLOW_LIST",
          resourceId: (_a = result == null ? void 0 : result.data) == null ? void 0 : _a.listing_id,
          data: { result: result == null ? void 0 : result.data }
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "ALLOW_LIST",
          error,
          resourceId: variables.listingId
        })
      );
    }
  });
};

// src/hooks/profileClosure.ts
import { useMutation as useMutation9 } from "@tanstack/react-query";
var useRequestProfileClosure = () => {
  const { dropApi } = useApi();
  return useMutation9({
    mutationFn: (_0) => __async(void 0, [_0], function* ({ organizationId }) {
      return dropApi.betaProfileClosureRequestControllerRequestProfileClosure(organizationId);
    })
  });
};

// src/hooks/emailTemplate.ts
import { usePluginRegistry as usePluginRegistry8 } from "plugin-system";
import { useMutation as useMutation10 } from "@tanstack/react-query";
var useUpdateEmailTemplate = () => {
  const { dropApi } = useApi();
  const pluginRegistry = usePluginRegistry8();
  const mutation = useMutation10({
    mutationFn: ({
      emailTemplateId,
      dto
    }) => dropApi.betaConfigureEmailTemplateControllerUpdatePartialEmailTemplateForDrop(emailTemplateId, dto).then((res) => res == null ? void 0 : res.data),
    onSuccess: (result, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "EMAIL_TEMPLATE",
          resourceId: variables.emailTemplateId,
          data: result
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new ApiActionEvent({
          type: "UPDATE",
          resource: "EMAIL_TEMPLATE",
          resourceId: variables.emailTemplateId,
          error
        })
      );
    }
  });
  return mutation;
};

// src/hooks/me.ts
import { useMutation as useMutation11 } from "@tanstack/react-query";
import { usePluginRegistry as usePluginRegistry9 } from "plugin-system";
var useCurrentPlatformUser = (enabled) => {
  const { coreApiBaseUrl, coreApi, axios } = useApi();
  const result = useResourceQuery(
    ["ORGANIZATION", "ME"],
    () => __async(void 0, null, function* () {
      try {
        const res = yield axios.get(`${coreApiBaseUrl}/session`);
        if (res.status === 200) {
          return coreApi == null ? void 0 : coreApi.meGet().then((res2) => res2.data);
        } else
          throw new Error("Session check failed");
      } catch (error) {
        throw error;
      }
    }),
    {
      enabled: enabled && !!coreApiBaseUrl,
      staleTime: 0,
      gcTime: 0
    }
  );
  return result;
};
var useEditCurrentUser = () => {
  const { coreApi } = useApi();
  const { data: platformUser } = useCurrentPlatformUser(true);
  const invalidate = useInvalidateResourceQuery();
  const pluginRegistry = usePluginRegistry9();
  return useMutation11({
    mutationFn: (data) => {
      var _a, _b;
      return coreApi.usersUserIdPatch((_b = (_a = platformUser == null ? void 0 : platformUser.user) == null ? void 0 : _a.id) != null ? _b : "", data);
    },
    onSuccess: () => {
      invalidate("ORGANIZATION", "ME");
    },
    onError: (error) => {
      var _a;
      pluginRegistry.events.publish(
        new EditUserErrorEvent({
          type: "UPDATE",
          resource: "USER",
          error,
          resourceId: (_a = platformUser == null ? void 0 : platformUser.user) == null ? void 0 : _a.id
        })
      );
    }
  });
};

// src/hooks/siwe-auth.ts
import { usePluginRegistry as usePluginRegistry10 } from "plugin-system";
import { useMutation as useMutation12, useQueryClient as useQueryClient3 } from "@tanstack/react-query";
var useChallengeGenerate = () => {
  const { coreApi } = useApi();
  const pluginRegistry = usePluginRegistry10();
  return useMutation12({
    mutationFn: ({ body }) => coreApi.challengeGeneratePost(body).then((res) => res == null ? void 0 : res.data),
    onSuccess: (_) => {
      pluginRegistry.events.publish(
        new CreateChallengeSuccessEvent({
          type: "CREATE",
          resource: "CHALLENGE"
        })
      );
    },
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new CreateChallengeFailureEvent({
          type: "CREATE",
          resource: "CHALLENGE",
          error,
          resourceId: variables.body.address
        })
      );
    }
  });
};
var useSiweLogin = () => {
  const { coreApi } = useApi();
  const pluginRegistry = usePluginRegistry10();
  const queryClient = useQueryClient3();
  return useMutation12({
    mutationFn: (_0) => __async(void 0, [_0], function* ({ body }) {
      var _a, _b;
      const res = yield coreApi.challengeLoginPost(body).then((res2) => {
        return res2 == null ? void 0 : res2.data;
      });
      if ((res == null ? void 0 : res.message) && ((_a = res == null ? void 0 : res.message) == null ? void 0 : _a.includes("success"))) {
        try {
          const me = yield coreApi == null ? void 0 : coreApi.meGet().then((res2) => res2.data);
          return !!((_b = me.user) == null ? void 0 : _b.id);
        } catch (e) {
          console.error("Failed to get platform user after success challenge login");
          return false;
        }
      } else {
        return false;
      }
    }),
    onSuccess: (_) => __async(void 0, null, function* () {
      pluginRegistry.events.publish(
        new VerifySignatureSuccessEvent({
          type: "CREATE",
          resource: "SIGNATURE_VERIFICATION"
        })
      );
      yield queryClient.invalidateQueries();
    }),
    onError: (error, variables) => {
      pluginRegistry.events.publish(
        new VerifySignatureFailureEvent({
          type: "CREATE",
          resource: "SIGNATURE_VERIFICATION",
          error,
          resourceId: variables.body.challenge_id
        })
      );
    }
  });
};
var useSiweLogout = () => {
  const { coreApi } = useApi();
  const queryClient = useQueryClient3();
  const pluginRegistry = usePluginRegistry10();
  return useMutation12({
    mutationFn: () => coreApi.challengeLogoutPost().then((res) => res == null ? void 0 : res.data),
    onSuccess: () => __async(void 0, null, function* () {
      pluginRegistry.events.publish(
        new VerifySignatureSuccessEvent({
          type: "DELETE",
          resource: "SIGNATURE_VERIFICATION"
        })
      );
      yield queryClient.cancelQueries();
    })
  });
};

// src/hooks/socials.ts
var usePublicSocialProfile = (address, options = {}) => {
  const { coreApiPublic } = useApi();
  return useResourceQuery(
    ["SOCIALS", address || ""],
    () => coreApiPublic.v1SocialsAddressGet(address).then((res) => res == null ? void 0 : res.data),
    __spreadValues({
      enabled: Boolean(address)
    }, LONG_LIVED_QRY_CONFIG)
  );
};

// src/hooks/creator-profile.ts
import { useEffect as useEffect6, useState as useState6 } from "react";
import { useMutation as useMutation13, useMutationState } from "@tanstack/react-query";
var useCreateCreatorProfilePageUri = (organization_id) => {
  const { dropApi } = useApi();
  const cachedData = useMutationState({
    // this mutation key needs to match the mutation key of the given mutation (see above)
    filters: { mutationKey: ["ORGANIZATION", organization_id || "", "PROFILE_URI"] },
    select: (mutation) => mutation.state.data
  });
  const { mutateAsync, isPending, data } = useMutation13({
    mutationFn: () => dropApi.betaConfigureDropControllerCreateCreatorProfilePageUri(),
    onSuccess: (result) => {
      return (result == null ? void 0 : result.data).absolute;
    },
    mutationKey: ["ORGANIZATION", organization_id || "", "PROFILE_URI"]
  });
  return { mutateAsync, isPending, data: (cachedData == null ? void 0 : cachedData[0]) || data };
};
var useCreatorProfilePageUri = (organization_id) => {
  const { dropApi } = useApi();
  const [result, setResult] = useState6({ isPending: false });
  const { mutate: generateFollowerUri, isPending } = useMutation13({
    mutationFn: () => dropApi.betaConfigureDropControllerCreateCreatorProfilePageUri(),
    onSuccess: (result2) => {
      setResult({ uri: (result2 == null ? void 0 : result2.data).absolute, isPending: false });
    },
    mutationKey: ["ORGANIZATION", organization_id || "", "PROFILE_URI"]
  });
  useEffect6(() => {
    setResult((old) => {
      return old ? __spreadProps(__spreadValues({}, old), { isPending }) : { isPending };
    });
  }, [isPending]);
  useEffect6(() => {
    if (!organization_id)
      return;
    generateFollowerUri();
  }, [generateFollowerUri, organization_id]);
  return result;
};

// src/types/drops.ts
var DropStatusValues = [
  "DRAFT",
  "IN_PROGRESS",
  "SCHEDULED",
  "IN_ERROR",
  "TIMED_OUT",
  "DROPPED",
  "LISTING_CREATED",
  "CLOSED",
  "PARTIALLY_COMPLETED",
  "TIMED_OUT",
  "COMPLETED"
];
var ItemDistributionStatusValues = [
  "NOT_STARTED",
  "FAILED",
  "IN_PROGRESS",
  "EXPIRED",
  "NOT_CLAIMED",
  "CLAIMED",
  "PARTIALLY_CLAIMED",
  "TRANSFERRED",
  "MINTED",
  "PURCHASED"
];
export {
  ApiActionEvent,
  CORE_API_SORT_DIRECTION_ASC,
  CORE_API_SORT_DIRECTION_DESC,
  CORE_DEFAULT_API_PAGINATION_LIMIT,
  CORE_HIGH_API_PAGINATION_LIMIT,
  CORE_MEDIUM_API_PAGINATION_LIMIT,
  CreateChallengeFailureEvent,
  CreateChallengeSuccessEvent,
  DUPLICATE_NAME_ERROR_MESSAGE,
  DropStatusValues,
  EditUserErrorEvent,
  ItemDistributionStatusValues,
  LONG_LIVED_QRY_CONFIG,
  LONG_LIVED_QRY_REFETCH_INTERVAL,
  LONG_LIVED_QRY_STALE,
  MEDIUM_LIVED_QRY_CONFIG,
  MEDIUM_LIVED_QRY_REFETCH_INTERVAL,
  MEDIUM_LIVED_STALE_QUERY,
  SHORT_LIVED_QRY_CONFIG,
  SHORT_LIVED_QRY_REFETCH_INTERVAL,
  SHORT_LIVED_QRY_STALE,
  VerifySignatureFailureEvent,
  VerifySignatureSuccessEvent,
  formatKeysForApi,
  getApiErrorMessage,
  getCoreApiErrorCode,
  getCoreApiErrorMessage,
  getCoreApiErrorStatus,
  isEmailAddress,
  useAddAdditionalDistribution,
  useAddAudienceListMember,
  useAddToAllowlist,
  useAllAudienceListMembers,
  useAllAudienceLists,
  useAllDropCostEstimations,
  useAllDropFlowDistributionsForAddress,
  useAllDropFlowDistributionsForWallet,
  useAllDropFlowListings,
  useAllDropItems,
  useAllPages,
  useAllProjects,
  useAllPublicNetworks,
  useAllRoles,
  useAllSnapshotHolderTokens,
  useAllSnapshotHoldersByAddress,
  useAllSnapshotHoldersByToken,
  useAllSnapshots,
  useApi,
  useAudienceList,
  useBalance,
  useBulkReplaceAllowlist,
  useCancelDropFlow,
  useChallengeGenerate,
  useCheckDropFlowAllowListPresence,
  useCheckListingAllowlistPresence,
  useCheckSnapshotPresence,
  useCloseDropFlowListingMintWindow,
  useCollection,
  useCollectionItems,
  useConvertDropToAdvancedDrop,
  useCreateAdditionalClaim,
  useCreateAdditionalClaimEmail,
  useCreateAdditionalIrlClaim,
  useCreateAdditionalTransfer,
  useCreateCreatorProfilePageUri,
  useCreateDropFlow,
  useCreateDropFlowConsumerUri,
  useCreateDropFlowItem,
  useCreateQRCodeForUrl,
  useCreateStripePaymentIntent,
  useCreatorProfilePageUri,
  useCurrentPlatformUser,
  useDeleteAllDropFlowItems,
  useDeleteAudienceList,
  useDeleteDrop,
  useDeleteDropFlow,
  useDeleteDropFlowItem,
  useDeleteListingAllowlist,
  useDeleteSnapshot,
  useDraftDropPeek,
  useDrop,
  useDropByCollectionId,
  useDropByContractAddress,
  useDropCostEstimate,
  useDropEffectiveCosts,
  useDropFlow,
  useDropFlowConsumerUri,
  useDropFlowCostEstimate,
  useDropFlowEmailTemplate,
  useDropFlowItemCount,
  useDropFlowWithItemSummary,
  useDropItem,
  useDropItemCountPeek,
  useDuplicateDrop,
  useEditCurrentUser,
  useEditListingAllowlist,
  useEditOrg,
  useEditOrgNotificationSettings,
  useEditOrgProfile,
  useEditSnapshot,
  useExecuteDropFlow,
  useExportAllSnapshotHoldersToCSV,
  useFullDropFlowAllowList,
  useInvalidateResourceQuery,
  useOrg,
  useOrgProfile,
  useOrganizationSettings,
  usePaginatedDropFlowDistributions,
  usePaginatedDropFlowItems,
  usePaginatedDrops,
  usePaginatedListingAllowlist,
  usePaginatedResourceQuery,
  useProject,
  usePublicSocialProfile,
  useRecentDrops,
  useRemoveFromAllowlist,
  useRequestProfileClosure,
  useResourceQuery,
  useShowSnapshotImage,
  useSingleCollectionItem,
  useSiweLogin,
  useSiweLogout,
  useSnapshot,
  useStatelessDropCostEstimate,
  useUpdateAllowlistEntry,
  useUpdateDrop,
  useUpdateDropFlow,
  useUpdateDropFlowItem,
  useUpdateDropFlowListingEligibility,
  useUpdateDropFlowListingMintWindow,
  useUpdateDropFlowListingPolicyType,
  useUpdateDropFlowSlug,
  useUpdateEmailTemplate,
  useValidateDropFlowSlugAvailability,
  useValidateOrgSlugAvailability
};
